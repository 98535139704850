(function (window, document, $) {

  $(document).ready(function () {

    const CodiceFiscale = require('codice-fiscale-js');
    const { Dropzone } = require("dropzone");

    const getFormData = function(form) {
      const formData = new FormData(form[0]);
      const data = {};
      formData.forEach((value, key) => {
        data[key] = value;
      });
      return data;
    }

    const populateForm = function(form, data) {
      Object.keys(data).forEach(key => {
        const input = form[0].elements[key];
        if (input) {
          if (input.type == 'file') {
            const dataTransfer = new DataTransfer();
            dataTransfer.items.add(data[key]);
            input.files = dataTransfer.files;
          } else if (input.type == 'checkbox') {
            if (data[key] == 1) {
              input.checked = true;
            } else {
              input.checked = false;
            }
          } else {
            input.value = data[key];
          }
        }
      });
    }

    const sendDataLayerEvent = function(event, data = {}, user_email) {
      if (typeof dataLayer !== 'undefined' && event && data) {
        const hash = function (string) {
          const utf8 = new TextEncoder().encode(string);
          return crypto.subtle.digest('SHA-256', utf8).then((hashBuffer) => {
            const hashArray = Array.from(new Uint8Array(hashBuffer));
            const hashHex = hashArray
              .map((bytes) => bytes.toString(16).padStart(2, '0'))
              .join('');
            return hashHex;
          });
        }
        const doSend = function (event, event_data, user_data) {
          // console.log("doSend");
          // console.log({
          //   event: event,
          //   event_data: event_data,
          //   ...(user_data ? {user: user_data} : {})
          // });
          dataLayer.push({
            event: event,
            event_data: event_data,
            ...(user_data ? {user: user_data} : {})
          });
        }
        if (user_email) {
          hash(user_email).then((email_sha256) => {
            doSend(event, data, {
              email: user_email,
              email_sha256: email_sha256
            });
          });
        } else {
          doSend(event, data);
        }
      }
    }
    const isOpenNow = function() {
      const now = new Date();
      const day = now.getDay(); // 0 = Domenica, 1 = Lunedì, ..., 6 = Sabato
      const hour = now.getHours(); // 0 - 23
      const minute = now.getMinutes(); // 0 - 59

      // Funzione per controllare se l'ora attuale è compresa tra due orari
      function isTimeInRange(startHour, startMinute, endHour, endMinute) {
        const startTime = startHour * 60 + startMinute;
        const endTime = endHour * 60 + endMinute;
        const currentTime = hour * 60 + minute;
        return currentTime >= startTime && currentTime <= endTime;
      }

      // Dal lunedì al venerdì
      if (day >= 1 && day <= 5) {
        return isTimeInRange(9, 0, 20, 0);
      }

      // Il sabato
      if (day === 6) {
        return isTimeInRange(9, 0, 13, 0);
      }

      // La domenica è chiuso
      return false;
    }

    const generateSecciPdf = function (payload, cb, err_cb) {
      const data = new FormData();
      data.append("action", 'generate_e2e_secci');
      data.append("payload", JSON.stringify(payload));
      data.append("_wpnonce", _nonce);

      fetch(ajax_url, {
        method: 'POST',
        body: data
      })
        .then(async (response) => {
          if (response.status === 200) {
            let filename = null;
            const contentDisposition = response.headers.get('Content-Disposition');
            if (contentDisposition) {
              const matches = contentDisposition.match(/filename="?(.[^"]+)"?/);
              if (matches && matches[1]) {
                filename = matches[1];
              }
            }
            try {
              const pdfBlob = await response.blob();
              const secciFile = new File([pdfBlob], filename ? filename : 'Secci.pdf', {type: 'application/pdf'});
              if (cb) {
                cb(secciFile);
              }
            } catch (e) {
              if (err_cb) {
                err_cb({
                  code: 500,
                  data: {message: 'File not valid'}
                });
              }
            }
          } else {
            const respJson = await response.json();
            if (err_cb) {
              err_cb({
                code: response.status,
                data: respJson
              });
            }
          }
        })
        .catch(error => {
          console.error('Errore:', error)
          if (err_cb) { err_cb(error) }
        });
    }

    const setFileToInput = function(file, input) {
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(file);
      input.files = dataTransfer.files;
    }

    const startFileDownload = function(file) {
      const url = URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = url;
      a.download = file.name;
      a.click();
      URL.revokeObjectURL(url);
    }

    const reloadDataTable = function (importo, cb) {
      const data = new FormData();
      data.append("action", 'update_preventivatore');
      data.append("importo", importo);
      $.ajax({
        type: "POST",
        enctype: 'multipart/form-data',
        url: ajax_url,
        data: data,
        processData: false,
        contentType: false,
        success: function (result) {
          let $res = JSON.parse(result);
          window.preventivatore_data_table = $res;
          if (cb) { cb($res); }
        }
      });
    }

    const refreshRates = function (data) {
      const $rate = $('.tim-rates .rate');
      const $tan = $('.tim-rates .tan');
      const $taeg = $('.tim-rates .taeg');
      const $disclaimer = $('.tim-note-description');
      window.tan = data.tan;
      window.taeg = data.taeg;
      $rate.html(data.num_rate);
      $tan.html(data.tan);
      $taeg.html(data.taeg);
      $disclaimer.html(data.disclaimer);
    }

    const formatValue = function(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €';
    }

    window.onNurtigoFormSubmitSuccess = function(form_id) {
      if (form_id == 14) {
        //
      } else {
        updateUrl({'step': 'thankyou'});
        const $form = $('#mauticform_formrichiestapreventivo');
        $user_email = $form.find('#mauticform_input_formrichiestapreventivo_email').val()
        //alert("STEP 4 SUBMITTED: importo " + window.importo + " mesi " + window.durata + " rata " + window.rata + " email " + $user_email);
        sendDataLayerEvent('form_submit_call_me', {
          currency: 'EUR',
          required_amount: window.importo,
          time: window.durata + ' mesi',
          monthly_installment: window.rata,
          installments: window.durata
        }, $user_email);
        const $step1_summary = $preventivatore.find('.tim-step-1-summary');
        $step1_summary.addClass('hide');
        const $step2_summary = $preventivatore.find('.tim-step-2-summary');
        $step2_summary.addClass('hide');
        const $step1 = $preventivatore.find('.tim-step-1');
        $step1.addClass('hide');
        const $step2 = $preventivatore.find('.tim-step-2');
        $step2.addClass('hide');
        const $step3 = $preventivatore.find('.tim-step-3');
        $step3.fadeOut().addClass('hide');
        const $thanyou = $preventivatore.find('#tim-thank-you-template');
        $thanyou.fadeIn();
        scrollToElement($thanyou);
      }
    }

    const updateUrl = function(params) {
      if (history.pushState) {
        const newurl = updateQueryParam(window.location.href, params);
        window.history.pushState({path:newurl},'', newurl);
      }
    }

    const $preventivatore = $('.tim-preventivatore');
    if ($preventivatore.length > 0) {

      //$preventivatore.on('touchstart mousedown', function(evt) {
      //  preventDoubleTapZoom(evt);
      //});

      if (!isOpenNow()) {
        const $parent = $preventivatore.find("#chiama-ora").parent();
        $preventivatore.find("#chiama-ora").remove();
        if ($parent.children().first().hasClass('tim-oppure')) {
          $parent.children().first().remove();
        }
      }


      // function limitScroll() {
      //   const maxScrollOffset = $('.tim-header-menu').height() + 44;
      //   console.log("========================");
      //   console.log(maxScrollOffset);
      //   console.log(window.scrollY);
      //   if (window.scrollY < maxScrollOffset) {
      //     window.scrollTo(0, maxScrollOffset);
      //     // $('html').animate(
      //     //   {
      //     //     scrollTop: maxScrollOffset,
      //     //   },
      //     //   400
      //     // );
      //   }
      // }
      // window.addEventListener('scroll', limitScroll);

      if (window.preventivatore_input_data.importo) {
        const $step2 = $preventivatore.find('.tim-step-2');
        scrollToElement($step2, 44 + 32);
        updateUrl({'step': 2});
        sendDataLayerEvent('step_1', {
          currency: 'EUR',
          required_amount: window.preventivatore_input_data.importo,
          time: window.preventivatore_input_data.durata + ' mesi'
        });
      } else {
        updateUrl({'step': 1});
      }
      window.preventivatore_input_data.importo = window.preventivatore_input_data.importo ?? '8000';
      window.preventivatore_input_data.durata = window.preventivatore_input_data.durata ?? '48';
      window.importo = window.preventivatore_input_data.importo;
      window.durata = window.preventivatore_input_data.durata;
      reloadDataTable(window.preventivatore_input_data.importo, function() {

        // Setup pickers
        // =====================================
        const $importoPicker = $preventivatore.find('#importo');
        $importoPicker.trigger('init', {
          'custom_display_fn': function (num) {
            return formatValue(num);
          }
        });
        $importoPicker.on('value_changed', function(e, importo) {
          window.importo = importo;
          updateUrl({'importo': importo});
          $preventivatore.find('#step_1_summary .tim-step-summary-value').html(formatValue(importo));
          if (window.importo_picker_debounce_timout) { clearTimeout(window.importo_picker_debounce_timout); }
          window.importo_picker_debounce_timout = setTimeout(function() {
            reloadDataTable(importo, function() {
              const $durataPicker = $preventivatore.find('#durata');
              $durataPicker.trigger('refresh_view');
            });
          }, 300);
        });

        const $durataSlider = $preventivatore.find('#durata_slider');
        $durataSlider.on('value_changed', function(e, durata) {
          window.durata = durata;
          updateUrl({'durata': durata});
          const $durataPicker = $preventivatore.find('#durata');
          $durataPicker.trigger('set_value', durata);
        });

        const $durataPicker = $preventivatore.find('#durata');
        $durataPicker.trigger('init', {
          'custom_display_fn': function (num) {
            const filtered = window.preventivatore_data_table ? window.preventivatore_data_table.filter((row) => {
              return row.num_rate == num;
            }) : [];
            let ret = '- €';
            if (filtered.length) {
              refreshRates(filtered[0]);
              window.rata = filtered[0].rata;
              window.id_preventivo = filtered[0].id_preventivo;
              ret = formatValue(filtered[0].rata);
            }
            $preventivatore.find('#step_2_summary .tim-step-summary-value').html(ret);
            return ret;
          }
        });
        $durataPicker.on('value_changed', function(e, durata) {
          window.durata = durata;
          updateUrl({'durata': durata});
          const $durataSlider = $preventivatore.find('#durata_slider');
          $durataSlider.trigger('set_value', durata);
        });
      });


      // window.tan_assicurazione = $preventivatore.find('.tim-grid-radio-cards .tim-radio-card:last .tan').html();
      // window.taeg_assicurazione = $preventivatore.find('.tim-grid-radio-cards .tim-radio-card:last .taeg').html();
      // window.rata_assicurazione = $preventivatore.find('.tim-grid-radio-cards .tim-radio-card:last .rata').html();
      // window.tan_no_assicurazione = $preventivatore.find('.tim-grid-radio-cards .tim-radio-card:first .tan').html();
      // window.taeg_no_assicurazione = $preventivatore.find('.tim-grid-radio-cards .tim-radio-card:first .taeg').html();
      // window.rata_no_assicurazione = $preventivatore.find('.tim-grid-radio-cards .tim-radio-card:first .rata').html();

      $preventivatore.find('#step_1_summary').on('click touch', function (evt) {
        evt.preventDefault();
        updateUrl({
          'importo': null,
          'durata': null,
          'step': 1
        });
        const $step1_summary = $preventivatore.find('.tim-step-1-summary');
        $step1_summary.fadeOut().addClass('hide');
        const $step2_summary = $preventivatore.find('.tim-step-2-summary');
        $step2_summary.fadeOut().addClass('hide');
        const $step1 = $preventivatore.find('.tim-step-1');
        $step1.fadeIn().removeClass('hide');
        const $step2 = $preventivatore.find('.tim-step-2');
        $step2.fadeOut().addClass('hide');
        const $step3 = $preventivatore.find('.tim-step-3');
        $step3.fadeOut().addClass('hide');
        scrollToElement($step1);
      });

      $preventivatore.find('#step_2_summary').on('click touch', function (evt) {
        evt.preventDefault();
        updateUrl({'step': 2});
        const $step2_summary = $preventivatore.find('.tim-step-2-summary');
        $step2_summary.fadeOut().addClass('hide');
        const $step2 = $preventivatore.find('.tim-step-2');
        $step2.fadeIn().removeClass('hide');
        const $step3 = $preventivatore.find('.tim-step-3');
        $step3.fadeOut().addClass('hide');
        scrollToElement($step2, 44 + 32);
      });

      $preventivatore.find('#step_1_cta').on('click touch', function (evt) {
        evt.preventDefault();
        updateUrl({
          'importo': window.importo,
          'durata': window.durata,
          'step': 2
        });
        //alert("STEP 1 SUBMITTED: importo " + window.importo + " mesi " + window.durata);
        sendDataLayerEvent('step_1', {
          currency: 'EUR',
          required_amount: window.importo,
          time: window.durata + ' mesi'
        });
        const $step1_summary = $preventivatore.find('.tim-step-1-summary');
        $step1_summary.css("display", "flex").fadeIn().removeClass('hide');
        const $step1 = $preventivatore.find('.tim-step-1');
        $step1.fadeOut().addClass('hide');
        const $step2 = $preventivatore.find('.tim-step-2');
        $step2.fadeIn().removeClass('hide');
        scrollToElement($step2, 44 + 32);
      });

      $preventivatore.find('#step_2_cta').on('click touch', function (evt) {
        evt.preventDefault();
        updateUrl({'step': 3});
        //alert("STEP 2 SUBMITTED: importo " + window.importo + " mesi " + window.durata + " rata " + window.rata);
        sendDataLayerEvent('step_2', {
          currency: 'EUR',
          monthly_installment: window.rata,
          installments: window.durata
        });
        const $step2_summary = $preventivatore.find('.tim-step-2-summary');
        $step2_summary.css("display", "flex").fadeIn().removeClass('hide');
        const $step2 = $preventivatore.find('.tim-step-2');
        $step2.fadeOut().addClass('hide');
        const $step3 = $preventivatore.find('.tim-step-3');
        $step3.fadeIn().removeClass('hide');
        scrollToElement($step3, 88 + 32);
      });

      $preventivatore.find("#ti-chiamiamo").on('click', function (e) {
        e.preventDefault();
        updateUrl({'step': 'form'});
        //alert("STEP 3 SUBMITTED: importo " + window.importo + " mesi " + window.durata + " rata " + window.rata);
        sendDataLayerEvent('click_call_me', {
          currency: 'EUR',
          required_amount: window.importo,
          time: window.durata + ' mesi',
          monthly_installment: window.rata,
          installments: window.durata
        });
        const $step4 = $preventivatore.find('#ti-chiamiamo-details');
        $step4.fadeIn(function() {
          $step4.show();
          scrollToElement($step4);
        });
      });
      const $step4Close = $preventivatore.find('#ti-chiamiamo-details .tim-fullscreen-step-back');
      $step4Close.on('click', function(e) {
        e.preventDefault();
        const $step4 = $preventivatore.find('#ti-chiamiamo-details');
        $step4.hide();
      });






      /////////////////////////////////////////////////////////
      // Inizio E2E
      ///////////////////////////////////////////////////////

      const $e2eSecciSectionSelector = '#e2e-secci-details';
      const $e2eSecciSection = $preventivatore.find($e2eSecciSectionSelector);
      const $e2eOmniaSectionSelector = '#e2e-omnia-details';
      const $e2eOmniaSection = $preventivatore.find($e2eOmniaSectionSelector);
      const $e2eAnagraficaSectionSelector = '#e2e-anagrafica-details';
      const $e2eAnagraficaSection = $preventivatore.find($e2eAnagraficaSectionSelector);
      const $e2eUploadSectionSelector = '#e2e-upload-details';
      const $e2eUploadSection = $preventivatore.find($e2eUploadSectionSelector);

      const resetE2E = function () {
        $e2eSecciSection.trigger('reset_e2e');
        $e2eOmniaSection.trigger('reset_e2e');
        $e2eAnagraficaSection.trigger('reset_e2e');
        $e2eUploadSection.trigger('reset_e2e');
      }

      const stopWithError = function ({preset, title, body} = {}) {
        $.modal.close();
        $errorModal = $('#custom-modal-error');
        $errorModal.find('.tim-title').html(title ?? "Qualcosa non ha funzionato :(");
        $errorModal.find('.tim-description').html(body ?? "Riprova più tardi o verifica la tua connessione internet");
        $errorModal.modal({
          fadeDuration: 200,
          fadeDelay: 0,
          closeExisting: true
        });
        resetE2E();
      }

      try {

        //  E2E Secci step
        // ====================================================
        const $e2eSecciForm = $e2eSecciSection.find('#mauticform_e2esecci');
        const $e2eSecciFormImporto = $e2eSecciForm.find('input[name="mauticform[importo]"]');
        const $e2eSecciFormDurata = $e2eSecciForm.find('input[name="mauticform[durata]"]');
        const $e2eSecciFormCheckbox = $e2eSecciForm.find('#mauticform_e2esecci_privacy input');
        const $e2eSecciFormCheckboxLabel = $e2eSecciForm.find('#mauticform_e2esecci_privacy label');
        const $e2eSecciFormSubmit = $e2eSecciForm.find('button[type="submit"]');
        const $e2eSecciFormSubmitAlias = $e2eSecciSection.find('#secci-form-submit-alias');
        const $e2eSecciDownloadBtn = $e2eSecciSection.find('#secci-download-cta');
        const $e2eSecciLoading = $e2eSecciSection.find('.tim-loading-secci');

        const validateSecciForm = function($form) {
          // Validate secci form and preventDefault() if not valid

          let valid = true;
          let scrollToTarget = null;
          const $nome = $form.find('input[name="mauticform[nome]"]');
          const $cognome = $form.find('input[name="mauticform[cognome]"]');
          const $codiceFiscale = $form.find('input[name="mauticform[codice_fiscale]"]');
          const $dataNascita = $form.find('input[name="mauticform[data_di_nascita]"]');
          const $luogoNascita = $form.find('input[name="mauticform[luogo_di_nascita]"]');
          const $cfErrorMsgElem = $codiceFiscale.closest('.mauticform-row').find('.mauticform-errormsg');
          scrollToTarget = $codiceFiscale[0];
          let cfData = null;
          if ($codiceFiscale.val() != '') {
            try {
              // const cf = new CodiceFiscale("ZRZDDD50H29Z600O"); // Esempio di codice fiscale nato estero
              cfData = new CodiceFiscale($codiceFiscale.val());
            } catch (e) {
              console.log(e);
              console.log("Codice fiscale non valido.")
            }

            if (cfData) {
              cfData = cfData.toJSON()
              $dataNascita.val(cfData.birthday);
              $luogoNascita.val(cfData.birthplace);
              const cfCheck = new CodiceFiscale({
                name: $nome.val(),
                surname: $cognome.val(),
                gender: cfData.gender,
                day: cfData.day,
                month: cfData.month,
                year: cfData.year,
                birthplace: cfData.birthplace,
                birthplaceProvincia: cfData.birthplaceProvincia
              });
              if (cfCheck.code.toUpperCase() !== $codiceFiscale.val().toUpperCase()) {
                valid = false;
                scrollToTarget = $nome[0];
                $cfErrorMsgElem[0].text_bck = $cfErrorMsgElem.html();
                $cfErrorMsgElem.html('Il codice fiscale inserito non corrisponde al nome e cognome specificati.');
                $cfErrorMsgElem.show();
              } else {
                if ($cfErrorMsgElem[0].text_bck) {
                  $cfErrorMsgElem.html($cfErrorMsgElem[0].text_bck);
                  $cfErrorMsgElem[0].text_bck = null;
                }
              }
            } else {
              valid = false;
              scrollToTarget = $codiceFiscale[0];
              $cfErrorMsgElem.show();
            }
          }

          return {valid: valid, scrollToTarget: scrollToTarget};
        }

        /////////////////////////////////////////////////////////
        // Handler evento "reset_e2e" per lo step "Secci"
        ///////////////////////////////////////////////////////
        $e2eSecciSection.on('reset_e2e', function (e) {
          // console.log("Resetting section " + $e2eSecciSectionSelector);
          $e2eSecciSection.hide();
          $e2eSecciForm.trigger('reset');
          window.e2e_secci_form_data = null;
          resetButtonLoading($e2eSecciFormSubmitAlias);
        });

        $preventivatore.find("#continua-online").on('click', function (e) {
          e.preventDefault();

          updateUrl({'E2E': 'inserimento-dati'});
          sendDataLayerEvent('E2E_step1_inserisci_i_tuoi_dati', {
            currency: 'EUR',
            required_amount: window.importo,
            time: window.durata + ' mesi',
            monthly_installment: window.rata,
            installments: window.durata
          });

          $e2eSecciFormImporto.val(window.importo);
          $e2eSecciFormDurata.val(window.durata);

          const $summaryAmount = $e2eSecciSection.find('#e2e-summary-amount');
          const $summaryInstallment = $e2eSecciSection.find('#e2e-summary-installment');
          const $summaryTime = $e2eSecciSection.find('#e2e-summary-time');
          const $summaryTan = $e2eSecciSection.find('#e2e-summary-tan');
          const $summaryTaeg = $e2eSecciSection.find('#e2e-summary-taeg');
          $e2eSecciFormSubmitAlias.attr('disabled', 'disabled');
          $summaryAmount.html(window.importo + '€');
          $summaryInstallment.html(window.rata + '€');
          $summaryTime.html(window.durata + ' mesi');
          $summaryTan.html(window.tan);
          $summaryTaeg.html(window.taeg);

          let secciFile = null;
          $e2eSecciDownloadBtn.off('click');
          $e2eSecciDownloadBtn.on('click', function (e) {
            e.preventDefault();
            if (secciFile) {
              startFileDownload(secciFile);
            }
          });

          $e2eSecciLoading.removeClass('hide');
          $e2eSecciDownloadBtn.addClass('hide');
          $e2eSecciFormSubmitAlias.attr('disabled', 'disabled');

          setTimeout(() => {
            generateSecciPdf({id_preventivo: window.id_preventivo}, async (file) => {
              try {
                const fileInput = document.getElementById('mauticform_input_e2esecci_secci_file');
                setFileToInput(file, fileInput);
                secciFile = file;
                const tipoEntitaField = document.getElementById('mauticform_input_e2esecci_tipo_entita');
                tipoEntitaField.value = 'lead';
                $e2eSecciLoading.addClass('hide');
                $e2eSecciDownloadBtn.removeClass('hide');
                $e2eSecciFormSubmitAlias.removeAttr('disabled');
              } catch (e) {
                stopWithError();
              }
            }, (error) => {
              stopWithError();
            });
          }, 1000);

          $e2eSecciFormSubmitAlias.off('click');
          $e2eSecciFormSubmitAlias.on('click', function (e) {
            $e2eSecciFormSubmit.click();
          });

          $e2eSecciForm.off('keydown');
          $e2eSecciForm.on('keydown', function (event) {
            if (event.key === 'Enter') {
              event.preventDefault();
              console.log("...!");
            }
          });

          $e2eSecciFormSubmit.off('click');
          $e2eSecciFormSubmit.on('click', function (evt) {
            setButtonLoading($e2eSecciFormSubmitAlias);
            const validationResult = validateSecciForm($e2eSecciForm);
            if (!validationResult.valid) {
              if (validationResult.scrollToTarget) {
                document.getElementById('e2e-secci-details').scrollTo({
                  top: validationResult.scrollToTarget.offsetTop - $('.tim-header-menu').height(),
                  behavior: "smooth"
                });
              }
              evt.preventDefault();
              resetButtonLoading($e2eSecciFormSubmitAlias);
            } else {
              window.e2e_secci_form_data = getFormData($e2eSecciForm);
            }
          });

          $e2eSecciFormCheckboxLabel.off('click');
          $e2eSecciFormCheckboxLabel.on('click', function(e) {
            if (e.target.nodeName !== 'A') {
              $e2eSecciFormCheckbox.prop('checked', !$e2eSecciFormCheckbox.prop('checked'));
            }
          });

          $(window).off('e2e_secci_form_submitted e2e_secci_form_validated');
          $(window).on('e2e_secci_form_submitted e2e_secci_form_validated', function (e, data) {
            if (e.type === 'e2e_secci_form_submitted') {
              if (data.success) {
                resetButtonLoading($e2eSecciFormSubmitAlias);
                $(window).trigger('goto_omnia_step');
                populateForm($e2eSecciForm, window.e2e_secci_form_data);
                document.getElementById('e2e-secci-details').scrollTo({
                  top: 0,
                  behavior: "smooth"
                });
              } else {
                stopWithError();
              }
            }
            if (e.type === 'e2e_secci_form_validated') {
              if (!data.formValid) {
                resetButtonLoading($e2eSecciFormSubmitAlias);
              }
            }
          });

          $e2eSecciSection.fadeIn(function () {
            $e2eSecciSection.show();
            scrollToElement($e2eSecciSection);
          });
        });
        const $e2eSecciSectionClose = $e2eSecciSection.find('.tim-fullscreen-step-back');
        $e2eSecciSectionClose.on('click', function (e) {
          e.preventDefault();
          document.getElementById('e2e-secci-details').scrollTo({
            top: 0,
            behavior: "smooth"
          });
          $e2eSecciSection.hide();
        });
        // ----------------------------------------------------

        const setButtonLoading = function ($button) {
          if (!$button.htmlBck) {
            $button.htmlBck = $button.html();
            $button.find('.tim-icon').remove();
            $button.find('.tim-text').addClass('ani-flash').html('Attendere...');
          }
        }
        const resetButtonLoading = function ($button) {
          if ($button.htmlBck) {
            $button.html($button.htmlBck);
            $button.htmlBck = null;
          }
        }

        const initOmniaForm = function ($form) {
          const $codiceFiscale = $form.find('input[name="mauticform[codice_fiscale]"]');
          const $email = $form.find('input[name="mauticform[email]"]');
          const $tipoEntita = $form.find('input[name="mauticform[tipo_entita]"]');
          if (window.e2e_secci_form_data['mauticform[codice_fiscale]'] !== '') {
            $codiceFiscale.val(window.e2e_secci_form_data['mauticform[codice_fiscale]']);
          }
          if (window.e2e_secci_form_data['mauticform[email]'] !== '') {
            $email.val(window.e2e_secci_form_data['mauticform[email]']);
          }
          if (window.e2e_secci_form_data['mauticform[tipo_entita]'] !== '') {
            $tipoEntita.val(window.e2e_secci_form_data['mauticform[tipo_entita]']);
          }

          if (!$codiceFiscale.val() || !$email.val() || !$tipoEntita.val()) {
            // Il form "Omnia" non è correttamente pre-popolato.
            // E' essenziale che i campi nascosti 'codice_fiscale', 'email' e 'tipo_entita' (campi identificatori di Nurtigo)
            // siano valorizzati per procedere
            return false;
          } else {
            return true;
          }
        }

        //  E2E Omnia step
        // ====================================================
        const $e2eOmniaForm = $preventivatore.find('#mauticform_e2emoduloomnia');
        const $e2eOmniaFormSubmit = $e2eOmniaForm.find('#mauticform_input_e2emoduloomnia_submit');
        const $e2eOmniaFormSubmitAlias = $preventivatore.find('#omnia-form-submit-alias');

        const validateOmniaForm = function($form) {
          // Validate omnia form and preventDefault() if not valid
          let validations = {};
          $form.find('input[name^="mauticform[omnia_"]').each((index, elem) => {
            validations[$(elem).attr('name')] = $(elem).val() !== '';
          });
          let valid = true;
          let scrollToTarget = null;
          Object.keys(validations).forEach((key) => {
            const match = key.match(/_(\d+)\]/);
            const checkboxIdx = match ? match[1] : '';
            const checkbox = $preventivatore.find('input[name="omnia-consent-' + checkboxIdx + '"]');
            if (validations[key] === false) {
              valid = false;
              checkbox.closest('.omnia-privacy-radiobtn').addClass('not-valid');
              if (!scrollToTarget) {
                scrollToTarget = checkbox.closest('.omnia-privacy-radiobtn').prev()[0];
              }
            } else {
              checkbox.closest('.omnia-privacy-radiobtn').removeClass('not-valid');
            }
          });

          return {valid: valid, scrollToTarget: scrollToTarget};
        }

        /////////////////////////////////////////////////////////
        // Handler evento "reset_e2e" per lo step "Omnia"
        ///////////////////////////////////////////////////////
        $e2eOmniaSection.on('reset_e2e', function (e) {
          // console.log("Resetting section " + $e2eOmniaSectionSelector);
          $e2eOmniaSection.hide();
          $e2eOmniaForm.trigger('reset');
          window.e2e_omnia_form_data = null;
          resetButtonLoading($e2eOmniaFormSubmitAlias);
        });

        $(window).on('goto_omnia_step', function (e) {
          e.preventDefault();

          updateUrl({'E2E': 'consensi-privacy'});
          sendDataLayerEvent('E2E_step2_solo_lead_consenso_privacy');

          if (!initOmniaForm($e2eOmniaForm)) {
            // Omnia form initialization failed
            console.error("Omnia form not ready!");
            stopWithError();
            return;
          }

          // Activate custom radio buttons
          $e2eOmniaSection.find('input[name^="omnia-consent"]').off('change');
          $e2eOmniaSection.find('input[name^="omnia-consent"]').on('change', function (e) {
            const selectedValue = $(this).val();
            const omniaFormInputName = $(this).attr('name').replace('-consent-', '_');
            $e2eOmniaForm.find('input[name="mauticform[' + omniaFormInputName + ']"]').val(selectedValue);
          });

          $e2eOmniaFormSubmitAlias.off('click');
          $e2eOmniaFormSubmitAlias.on('click', function (e) {
            $e2eOmniaFormSubmit.click();
          });

          $e2eOmniaForm.off('keydown');
          $e2eOmniaForm.on('keydown', function (event) {
            if (event.key === 'Enter') {
              event.preventDefault();
              console.log("...!");
            }
          });

          $e2eOmniaFormSubmit.off('click');
          $e2eOmniaFormSubmit.on('click', function (evt) {
            setButtonLoading($e2eOmniaFormSubmitAlias);
            const validationResult = validateOmniaForm($e2eOmniaForm);
            if (!validationResult.valid) {
              if (validationResult.scrollToTarget) {
                document.getElementById('e2e-omnia-details').scrollTo({
                  top: validationResult.scrollToTarget.offsetTop - $('.tim-header-menu').height(),
                  behavior: "smooth"
                });
              }
              evt.preventDefault();
              resetButtonLoading($e2eOmniaFormSubmitAlias);
            } else {
              window.e2e_omnia_form_data = getFormData($e2eOmniaForm);
            }
          });

          $(window).off('e2e_omnia_form_submitted e2e_omnia_form_validated');
          $(window).on('e2e_omnia_form_submitted e2e_omnia_form_validated', function (e, data) {
            if (e.type === 'e2e_omnia_form_submitted') {
              if (data.success) {
                // console.log("Omnia form submitted correctly!");
                resetButtonLoading($e2eOmniaFormSubmitAlias);
                $(window).trigger('goto_anagrafica_step');
                populateForm($e2eOmniaForm, window.e2e_omnia_form_data);
                document.getElementById('e2e-omnia-details').scrollTo({
                  top: 0,
                  behavior: "smooth"
                });
              } else {
                stopWithError();
              }
            }
            if (e.type === 'e2e_omnia_form_validated') {
              if (!data.formValid) {
                resetButtonLoading($e2eOmniaFormSubmitAlias);
              }
            }
          });

          $e2eOmniaSection.fadeIn(function () {
            $e2eOmniaSection.show();
            scrollToElement($e2eOmniaSection);
          });
        });
        const $e2eOmniaSectionClose = $e2eOmniaSection.find('.tim-fullscreen-step-back');
        $e2eOmniaSectionClose.on('click', function (e) {
          e.preventDefault();
          $e2eOmniaSection.hide();
          document.getElementById('e2e-omnia-details').scrollTo({
            top: 0,
            behavior: "smooth"
          });
          updateUrl({'E2E': 'inserimento-dati'});
        });
        // ----------------------------------------------------

        const initAnagraficaForm = function ($form) {
          const $codiceFiscale = $form.find('input[name="mauticform[codice_fiscale]"]');
          const $email = $form.find('input[name="mauticform[email]"]');
          const $tipoEntita = $form.find('input[name="mauticform[tipo_entita]"]');
          const $nome = $form.find('input[name="mauticform[nome]"]');
          const $cognome = $form.find('input[name="mauticform[cognome]"]');
          const $dataNascita = $form.find('input[name="mauticform[data_di_nascita]"]');
          const $luogoNascita = $form.find('input[name="mauticform[luogo_di_nascita]"]');
          const $telefono = $form.find('input[name="mauticform[telefono]"]');

          // TODO - Nascondere in accordion
          if ($form.find('#anagrafica-accordion').length === 0) {
            const $formPageWrapper = $form.find('.mauticform-page-1');
            const $wrapperToggler = $('#anagrafica-accordion-toggler');
            const $wrapper = $('<div id="anagrafica-accordion" style="display: none;"></div>');
            $formPageWrapper.prepend($wrapper);
            $formPageWrapper.prepend($wrapperToggler);
            $nome.closest('.mauticform-row').appendTo($wrapper);
            $cognome.closest('.mauticform-row').appendTo($wrapper);
            $codiceFiscale.closest('.mauticform-row').appendTo($wrapper);
            $dataNascita.closest('.mauticform-row').appendTo($wrapper);
            $luogoNascita.closest('.mauticform-row').appendTo($wrapper);
            $telefono.closest('.mauticform-row').appendTo($wrapper);
            $email.closest('.mauticform-row').appendTo($wrapper);
            $wrapperToggler.show();
            $wrapperToggler.off('click');
            $wrapperToggler.on('click', function (e) {
              $wrapperToggler.find('svg').toggleClass('rotate');
              $wrapper.slideToggle(500);
            });
          }

          if (window.e2e_secci_form_data['mauticform[codice_fiscale]'] !== '') {
            $codiceFiscale.val(window.e2e_secci_form_data['mauticform[codice_fiscale]']);
            $codiceFiscale.attr('readonly', 'true');
          }
          if (window.e2e_secci_form_data['mauticform[email]'] !== '') {
            $email.val(window.e2e_secci_form_data['mauticform[email]']);
            $email.attr('readonly', 'true');
          }
          if (window.e2e_secci_form_data['mauticform[tipo_entita]'] !== '') {
            $tipoEntita.val(window.e2e_secci_form_data['mauticform[tipo_entita]']);
          }
          if (window.e2e_secci_form_data['mauticform[nome]'] !== '') {
            $nome.val(window.e2e_secci_form_data['mauticform[nome]']);
            $nome.attr('readonly', 'true');
          }
          if (window.e2e_secci_form_data['mauticform[cognome]'] !== '') {
            $cognome.val(window.e2e_secci_form_data['mauticform[cognome]']);
            $cognome.attr('readonly', 'true');
          }
          if (window.e2e_secci_form_data['mauticform[data_di_nascita]'] !== '') {
            $dataNascita.val(window.e2e_secci_form_data['mauticform[data_di_nascita]']);
            $dataNascita.attr('readonly', 'true');
          }
          if (window.e2e_secci_form_data['mauticform[luogo_di_nascita]'] !== '') {
            $luogoNascita.val(window.e2e_secci_form_data['mauticform[luogo_di_nascita]']);
            $luogoNascita.attr('readonly', 'true');
          }
          if (window.e2e_secci_form_data['mauticform[telefono]'] !== '') {
            $telefono.val(window.e2e_secci_form_data['mauticform[telefono]']);
          }

          if (!$codiceFiscale.val() || !$email.val() || !$tipoEntita.val()) {
            // Il form "Anagrafica" non è correttamente pre-popolato.
            // E' essenziale che i campi nascosti 'codice_fiscale', 'email' e 'tipo_entita' (campi identificatori di Nurtigo)
            // siano valorizzati per procedere
            return false;
          } else {
            return true;
          }
        }

        //  E2E Anagrafica step
        // ====================================================
        const $e2eAnagraficaForm = $e2eAnagraficaSection.find('#mauticform_e2eanagrafica');
        const $e2eAnagraficaFormDomicilioFlag = $e2eAnagraficaForm.find('#mauticform_checkboxgrp_checkbox_domicilio_diverso_10');
        const $e2eAnagraficaFormDomicilioRows = $e2eAnagraficaForm.find('[data-domicilio-field]');
        const $e2eAnagraficaFormDomicilioInputs = $e2eAnagraficaForm.find('[data-domicilio-field] input');
        const $e2eAnagraficaFormDomicilioSelects = $e2eAnagraficaForm.find('[data-domicilio-field] select');
        const $e2eAnagraficaFormSubmit = $e2eAnagraficaForm.find('#mauticform_input_e2eanagrafica_submit');
        const $e2eAnagraficaFormSubmitAlias = $e2eAnagraficaSection.find('#anagrafica-form-submit-alias');

        const validateAnagraficaForm = function($form) {
          // Validate anagrafica form and preventDefault() if not valid

          let valid = true;
          let scrollToTarget = null;
          const $domFlag = $form.find('input[name="mauticform[domicilio_diverso][]"]');
          const $domIndirizzo = $form.find('input[name="mauticform[dom_indirizzo]"]');
          const $domCitta = $form.find('input[name="mauticform[dom_citta]"]');
          const $domProvincia = $form.find('select[name="mauticform[dom_provincia]"]');
          const $domCap = $form.find('input[name="mauticform[dom_cap]"]');
          if ($domFlag.is(':checked')) {
            if (!$domIndirizzo.val()) {
              $domIndirizzo.siblings('.mauticform-errormsg').html("Inserisci l'indirizzo di domicilio").show();
              valid = false;
            } else {
              $domIndirizzo.siblings('.mauticform-errormsg').hide();
            }
            if (!$domCitta.val()) {
              $domCitta.siblings('.mauticform-errormsg').html("Inserisci la città di domicilio").show();
              valid = false;
            } else {
              $domCitta.siblings('.mauticform-errormsg').hide();
            }
            if (!$domProvincia.val()) {
              $domProvincia.siblings('.mauticform-errormsg').html("Inserisci la provincia di domicilio").show();
              valid = false;
            } else {
              $domProvincia.siblings('.mauticform-errormsg').hide();
            }
            if (!$domCap.val()) {
              $domCap.siblings('.mauticform-errormsg').html("Inserisci il CAP di domicilio").show();
              valid = false;
            } else {
              $domCap.siblings('.mauticform-errormsg').hide();
            }
          }

          scrollToTarget = $domIndirizzo[0];
          return {valid: valid, scrollToTarget: scrollToTarget};
        }

        /////////////////////////////////////////////////////////
        // Handler evento "reset_e2e" per lo step "Anagrafica"
        ///////////////////////////////////////////////////////
        $e2eAnagraficaSection.on('reset_e2e', function (e) {
          // console.log("Resetting section " + $e2eAnagraficaSectionSelector);
          $e2eAnagraficaSection.hide();
          $e2eAnagraficaFormDomicilioRows.hide();
          $e2eAnagraficaFormDomicilioInputs.val('');
          $e2eAnagraficaFormDomicilioSelects.val('');
          $e2eAnagraficaForm.trigger('reset');
          window.e2e_anagrafica_form_data = null;
          resetButtonLoading($e2eAnagraficaFormSubmitAlias);
        });

        $(window).on('goto_anagrafica_step', function (e) {
          e.preventDefault();

          updateUrl({'E2E': 'dati-residenza'});
          sendDataLayerEvent('E2E_step3_dati_di_residenza');

          if (!initAnagraficaForm($e2eAnagraficaForm)) {
            // Anagrafica form initialization failed
            console.error("Anagrafica form not ready!");
            stopWithError();
            return;
          }

          $e2eAnagraficaFormDomicilioRows.hide();
          $e2eAnagraficaFormDomicilioFlag.off('change');
          $e2eAnagraficaFormDomicilioFlag.on('change', function (e) {
            if ($e2eAnagraficaFormDomicilioFlag.is(':checked')) {
              $e2eAnagraficaFormDomicilioRows.show();
              $e2eAnagraficaFormDomicilioInputs.val('');
              $e2eAnagraficaFormDomicilioSelects.val('');
            } else {
              $e2eAnagraficaFormDomicilioRows.hide();
              $e2eAnagraficaFormDomicilioInputs.val('');
              $e2eAnagraficaFormDomicilioSelects.val('');
            }
          });

          $e2eAnagraficaFormSubmitAlias.off('click');
          $e2eAnagraficaFormSubmitAlias.on('click', function (e) {
            $e2eAnagraficaFormSubmit.click();
          });

          $e2eAnagraficaForm.off('keydown');
          $e2eAnagraficaForm.on('keydown', function (event) {
            if (event.key === 'Enter') {
              event.preventDefault();
              console.log("...!");
            }
          });

          $e2eAnagraficaFormSubmit.off('click');
          $e2eAnagraficaFormSubmit.on('click', function (evt) {
            setButtonLoading($e2eAnagraficaFormSubmitAlias);
            const validationResult = validateAnagraficaForm($e2eAnagraficaForm);
            if (!validationResult.valid) {
              if (validationResult.scrollToTarget) {
                document.getElementById('e2e-anagrafica-details').scrollTo({
                  top: validationResult.scrollToTarget.offsetTop - $('.tim-header-menu').height(),
                  behavior: "smooth"
                });
              }
              evt.preventDefault();
              resetButtonLoading($e2eAnagraficaFormSubmitAlias);
            } else {
              window.e2e_anagrafica_form_data = getFormData($e2eAnagraficaForm);
            }
          });

          $(window).off('e2e_anagrafica_form_submitted e2e_anagrafica_form_validated');
          $(window).on('e2e_anagrafica_form_submitted e2e_anagrafica_form_validated', function (e, data) {
            if (e.type === 'e2e_anagrafica_form_submitted') {
              if (data.success) {
                // console.log("Anagrafica form submitted correctly!");
                resetButtonLoading($e2eAnagraficaFormSubmitAlias);
                $(window).trigger('goto_upload_step');
                populateForm($e2eAnagraficaForm, window.e2e_anagrafica_form_data);
                document.getElementById('e2e-anagrafica-details').scrollTo({
                  top: 0,
                  behavior: "smooth"
                });
              } else {
                stopWithError();
              }
            }
            if (e.type === 'e2e_anagrafica_form_validated') {
              if (!data.formValid) {
                resetButtonLoading($e2eAnagraficaFormSubmitAlias);
              }
            }
          });

          $e2eAnagraficaSection.fadeIn(function () {
            $e2eAnagraficaSection.show();
            scrollToElement($e2eAnagraficaSection);
          });
        });
        const $e2eAnagraficaSectionClose = $e2eAnagraficaSection.find('.tim-fullscreen-step-back');
        $e2eAnagraficaSectionClose.on('click', function (e) {
          e.preventDefault();
          $e2eAnagraficaSection.hide();
          document.getElementById('e2e-anagrafica-details').scrollTo({
            top: 0,
            behavior: "smooth"
          });
          updateUrl({'E2E': 'consensi-privacy'});
        });
        // ----------------------------------------------------

        const initUploadForm = function ($form) {
          const $codiceFiscale = $form.find('input[name="mauticform[codice_fiscale]"]');
          const $email = $form.find('input[name="mauticform[email]"]');
          const $tipoEntita = $form.find('input[name="mauticform[tipo_entita]"]');
          // const $laterUpload = $form.find('input[name="mauticform[later_upload]"]');
          if (window.e2e_secci_form_data['mauticform[codice_fiscale]'] !== '') {
            $codiceFiscale.val(window.e2e_secci_form_data['mauticform[codice_fiscale]']);
          }
          if (window.e2e_secci_form_data['mauticform[email]'] !== '') {
            $email.val(window.e2e_secci_form_data['mauticform[email]']);
          }
          if (window.e2e_secci_form_data['mauticform[tipo_entita]'] !== '') {
            $tipoEntita.val(window.e2e_secci_form_data['mauticform[tipo_entita]']);
          }

          // $laterUpload.val('0');

          if (!$codiceFiscale.val() || !$email.val() || !$tipoEntita.val()) {
            // Il form "Upload" non è correttamente pre-popolato.
            // E' essenziale che i campi nascosti 'codice_fiscale', 'email' e 'tipo_entita' (campi identificatori di Nurtigo)
            // siano valorizzati per procedere
            return false;
          } else {
            return true;
          }
        }

        //  E2E Upload step
        // ====================================================
        const $e2eUploadForm = $e2eUploadSection.find('#mauticform_e2eupload');
        const $e2eUploadLaterForm = $e2eUploadSection.find('#mauticform_e2euploadlater');
        // const $e2eUploadFormLaterUploadInput = $e2eUploadForm.find('input[name="mauticform[later_upload]"]');
        const $e2eUploadFormSubmit = $e2eUploadForm.find('#mauticform_input_e2eupload_submit');
        const $e2eUploadFormSubmitAlias = $e2eUploadSection.find('#upload-form-submit-alias');
        const $e2eUploadLaterFormSubmit = $e2eUploadLaterForm.find('#mauticform_input_e2euploadlater_submit');
        const $e2eUploadLaterFormSubmitAlias = $e2eUploadSection.find('#later-upload-form-submit-alias');

        const validateInputFile = function(inputElement) {
          const $file = inputElement.files[0];
          if ($file) {
            const allowedTypes = ["image/png", "image/jpeg", "application/pdf"];
            if (!allowedTypes.includes($file.type)) {
              $(inputElement).siblings('.mauticform-errormsg').html("Tipo di file non consentito").show();
              return false;
            }
            const megabyte = 10
            const maxSizeInBytes = megabyte * 1024 * 1024;
            if ($file.size > maxSizeInBytes) {
              $(inputElement).siblings('.mauticform-errormsg').html("Il file è troppo grande. Massimo " + megabyte + " MB").show();
              return false;
            }
            $(inputElement).siblings('.mauticform-errormsg').hide();
          } else {
            $(inputElement).siblings('.mauticform-errormsg').html("Seleziona un file").show();
            return false;
          }
          return true;
        }

        const validateUploadForm = function($form) {
          // Validate upload form and preventDefault() if not valid

          let validations = {};
          $form.find('input[type="file"]').each((index, elem) => {
            validations[$(elem).attr('name')] = validateInputFile(elem);
          });
          let valid = true;
          let scrollToTarget = null;
          Object.keys(validations).forEach((key) => {
            const match = key.match(/_(\d+)\]/);
            const checkboxIdx = match ? match[1] : '';
            const checkbox = $preventivatore.find('input[name="omnia-consent-' + checkboxIdx + '"]');
            if (validations[key] === false) {
              valid = false;
              if (!scrollToTarget) {
                scrollToTarget = $form.find('input[name="' + key + '"]').closest('.mauticform-row')[0];
              }
            }
          });

          return {valid: valid, scrollToTarget: scrollToTarget};
        }

        /////////////////////////////////////////////////////////
        // Handler evento "reset_e2e" per lo step "Upload"
        ///////////////////////////////////////////////////////
        $e2eUploadSection.on('reset_e2e', function (e) {
          // console.log("Resetting section " + $e2eUploadSectionSelector);
          $e2eUploadSection.hide();
          $e2eUploadForm.trigger('reset');
          window.e2e_upload_form_data = null;
          resetButtonLoading($e2eUploadFormSubmitAlias);
        });

        $(window).on('goto_upload_step', function (e) {
          e.preventDefault();

          updateUrl({'E2E': 'caricamento-documenti'});
          sendDataLayerEvent('E2E_step4_caricamento_documenti');

          if (!initUploadForm($e2eUploadForm) || !initUploadForm($e2eUploadLaterForm)) {
            // Upload form initialization failed
            console.error("Upload form not ready!");
            stopWithError();
            return;
          }

          $e2eUploadForm.find('input[type="file"]').off('change');
          $e2eUploadForm.find('input[type="file"]').on('change', function(evt) {
            validateInputFile(this);
          });

          $e2eUploadFormSubmitAlias.off('click');
          $e2eUploadFormSubmitAlias.on('click', function (e) {
            setButtonLoading($e2eUploadFormSubmitAlias);
            // $e2eUploadFormLaterUploadInput.val('0');
            $e2eUploadFormSubmit.click();
          });

          $e2eUploadLaterFormSubmitAlias.off('click');
          $e2eUploadLaterFormSubmitAlias.on('click', function (e) {
            setButtonLoading($e2eUploadLaterFormSubmitAlias);
            // $e2eUploadFormLaterUploadInput.val('1');
            $e2eUploadLaterFormSubmit.click();
          });

          $e2eUploadForm.off('keydown');
          $e2eUploadForm.on('keydown', function (event) {
            if (event.key === 'Enter') {
              event.preventDefault();
              console.log("...!");
            }
          });

          $e2eUploadFormSubmit.off('click');
          $e2eUploadFormSubmit.on('click', function (evt) {
            const validationResult = validateUploadForm($e2eUploadForm);
            if (!validationResult.valid) {
              if (validationResult.scrollToTarget) {
                document.getElementById('e2e-upload-details').scrollTo({
                  top: validationResult.scrollToTarget.offsetTop - $('.tim-header-menu').height(),
                  behavior: "smooth"
                });
              }
              evt.preventDefault();
              resetButtonLoading($e2eUploadFormSubmitAlias);
              resetButtonLoading($e2eUploadLaterFormSubmitAlias);
            } else {
              window.e2e_upload_form_data = getFormData($e2eUploadForm);
            }
            // window.e2e_upload_form_data = getFormData($e2eUploadForm);
          });

          $(window).off('e2e_upload_later_form_submitted e2e_upload_form_validated');
          $(window).on('e2e_upload_later_form_submitted e2e_upload_form_validated', function (e, data) {
            if (e.type === 'e2e_upload_later_form_submitted') {
              if (data.success) {
                // console.log("Upload later form submitted correctly!");
                resetButtonLoading($e2eUploadFormSubmitAlias);
                resetButtonLoading($e2eUploadLaterFormSubmitAlias);
                //resetE2E();

                updateUrl({'E2E': 'thankyou-senza-documenti'});
                sendDataLayerEvent('E2E_step5_lead_senza_documenti');

                const thankyouSection = $preventivatore.find('#e2e-later-thankyou');

                const $step1_summary = $preventivatore.find('.tim-step-1-summary');
                $step1_summary.addClass('hide');
                const $step2_summary = $preventivatore.find('.tim-step-2-summary');
                $step2_summary.addClass('hide');
                const $step1 = $preventivatore.find('.tim-step-1');
                $step1.addClass('hide');
                const $step2 = $preventivatore.find('.tim-step-2');
                $step2.addClass('hide');
                const $step3 = $preventivatore.find('.tim-step-3');
                $step3.fadeOut().addClass('hide');
                thankyouSection.fadeIn();
                scrollToElement(thankyouSection, 30);

                // populateForm($e2eUploadForm, window.e2e_upload_form_data);
              } else {
                stopWithError();
              }
            }
            if (e.type === 'e2e_upload_later_form_validated') {
              if (!data.formValid) {
                resetButtonLoading($e2eUploadFormSubmitAlias);
                resetButtonLoading($e2eUploadLaterFormSubmitAlias);
              }
            }
          });

          $(window).off('e2e_upload_form_submitted e2e_upload_form_validated');
          $(window).on('e2e_upload_form_submitted e2e_upload_form_validated', function (e, data) {
            if (e.type === 'e2e_upload_form_submitted') {
              if (data.success) {
                // console.log("Upload form submitted correctly!");
                resetButtonLoading($e2eUploadFormSubmitAlias);
                resetButtonLoading($e2eUploadLaterFormSubmitAlias);
                //resetE2E();

                updateUrl({'E2E': 'thankyou-con-documenti'});
                sendDataLayerEvent('E2E_step5_lead_con_documenti');

                const thankyouSection = $preventivatore.find('#e2e-thankyou');

                const $step1_summary = $preventivatore.find('.tim-step-1-summary');
                $step1_summary.addClass('hide');
                const $step2_summary = $preventivatore.find('.tim-step-2-summary');
                $step2_summary.addClass('hide');
                const $step1 = $preventivatore.find('.tim-step-1');
                $step1.addClass('hide');
                const $step2 = $preventivatore.find('.tim-step-2');
                $step2.addClass('hide');
                const $step3 = $preventivatore.find('.tim-step-3');
                $step3.fadeOut().addClass('hide');
                thankyouSection.fadeIn();
                scrollToElement(thankyouSection, 30);

                // populateForm($e2eUploadForm, window.e2e_upload_form_data);
              } else {
                stopWithError();
              }
            }
            if (e.type === 'e2e_upload_form_validated') {
              if (!data.formValid) {
                resetButtonLoading($e2eUploadFormSubmitAlias);
                resetButtonLoading($e2eUploadLaterFormSubmitAlias);
              }
            }
          });

          $e2eUploadSection.fadeIn(function () {
            $e2eUploadSection.show();
            scrollToElement($e2eUploadSection);
          });
        });
        const $e2eUploadSectionClose = $e2eUploadSection.find('.tim-fullscreen-step-back');
        $e2eUploadSectionClose.on('click', function (e) {
          e.preventDefault();
          $e2eUploadSection.hide();
          updateUrl({'E2E': 'dati-residenza'});
        });
        // ----------------------------------------------------
      } catch (e) {
        stopWithError();
      }

      $preventivatore.find("#chiama-ora .overlay").on('click', function(e) {
        //alert("STEP 5 SUBMITTED: importo " + window.importo + " mesi " + window.durata + " rata " + window.rata);
        sendDataLayerEvent('form_submit_call_now', {
          currency: 'EUR',
          required_amount: window.importo,
          time: window.durata + ' mesi',
          monthly_installment: window.rata,
          installments: window.durata
        });
        window.location = 'tel:800506110'
      });
    }
  });

  if (typeof window.MauticFormCallback === 'undefined') {
    window.MauticFormCallback = {};
  }
  window.MauticFormCallback['e2esecci'] = {
    onResponseEnd: function (response) {
      // console.log("response");
      // console.log(response);
      $(window).trigger('e2e_secci_form_submitted', {success: response.success == 1});
    },
    onValidateEnd: function (formValid) {
      $(window).trigger('e2e_secci_form_validated', {formValid: formValid});
    }
  }
  window.MauticFormCallback['e2emoduloomnia'] = {
    onResponseEnd: function (response) {
      // console.log("Trigger Omnia form");
      $(window).trigger('e2e_omnia_form_submitted', {success: response.success == 1});
    },
    onValidateEnd: function (formValid) {
      $(window).trigger('e2e_omnia_form_validated', {formValid: formValid});
    }
  }
  window.MauticFormCallback['e2eanagrafica'] = {
    onResponseEnd: function (response) {
      // console.log("Trigger Anagrafica form");
      $(window).trigger('e2e_anagrafica_form_submitted', {success: response.success == 1});
    },
    onValidateEnd: function (formValid) {
      $(window).trigger('e2e_anagrafica_form_validated', {formValid: formValid});
    }
  }
  window.MauticFormCallback['e2eupload'] = {
    onResponseEnd: function (response) {
      // console.log("Trigger Upload form");
      $(window).trigger('e2e_upload_form_submitted', {success: response.success == 1});
    },
    onValidateEnd: function (formValid) {
      $(window).trigger('e2e_upload_form_validated', {formValid: formValid});
    }
  }
  window.MauticFormCallback['e2euploadlater'] = {
    onResponseEnd: function (response) {
      // console.log("Trigger Upload later form");
      $(window).trigger('e2e_upload_later_form_submitted', {success: response.success == 1});
    },
    onValidateEnd: function (formValid) {
      $(window).trigger('e2e_upload_later_form_validated', {formValid: formValid});
    }
  }

})(window, document, jQuery);
